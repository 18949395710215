const palette = {
  primary: {
    main: '#01796f',
  },
  secondary: {
    main: '#eef8f6',
    light: '#def2ee',
  },
};

export default palette;
