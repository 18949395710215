const routes = {
  home: '/',
  login: 'login',
  register: 'register',
  notFound: '*',
  aboutUs: '/#about',
  simulator: '/#simulator',
  howItWorks: '/#howitwork',
  contact: 'https://wa.me/+2250708064848',
  tel: 'tel:+2250708064848',
  email: 'mailto:team@coollionfi.com',
  videoChat: 'https://meet.google.com/zkm-vbix-kxr',
  dashboard: 'dashbaord',
  account: 'account',
  setting: 'settings',
  customers: 'customers',
  donor: 'donor',
  beneficiary: 'beneficiary',
  request: 'request',
  maturity: 'maturity',
  product: 'products',
  messenger: 'messenger',
};

export default routes;
